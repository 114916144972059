

#testimonial-slider{
    padding: 0px 20px 0px 20px;
    background: none;
    text-align: center;
    
}



.p-relitive{
    position: relative;
}

.control-testimonial{
    margin: auto;
}
.owl-carousel{
    position:inherit !important;
}

.testimonial{
    box-shadow: 0px 15px 27px 0px #00000014;
    background: #fff;
}
.testimonial .description{
    font-size: 18px;
    color: #7E8696;
    line-height: 26px;
    text-align: left;
    position: relative;
    background: #fff;
    padding: 100px 80px 40px 80px;
    font-weight: 400px;
    box-shadow: none !important;
    font-family: 'Poppins', sans-serif !important;
}

.testimonial .description:before{
    content: "\f10d";
    font-family: "Font Awesome 5 Free";font-weight: 900;
    position: absolute;
    top: 50px;
    left: 80px;
    font-size: 70px;
    color: #000;
   
}




.owl-buttons{
    width: 100%;
    height: 40px;
    position: absolute;
    top: 32%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.owl-prev,
.owl-next{
    position: absolute;
    left: -19px;
    transition: all 0.4s ease-in-out 0s;
    background: none !important;
}

.owl-next{
    left: auto;
    right: -19px;
}

.owl-buttons .owl-prev:before,
.owl-buttons .owl-next:before{
    content: "\f104";
    font-family: "Font Awesome 5 Free";font-weight: 900;
    font-size: 30px;
    font-weight: 900;
    color: #374957;
    line-height: 20px;
    opacity: 0.8;
}

.owl-buttons .owl-next:before{
    content: "\f105";
}

.owl-buttons .owl-prev:hover:before,
.owl-buttons .owl-next:hover:before{
    color: #667EEA;
}

.owl-theme .owl-controls .owl-buttons div{
    background: transparent;
}

.owl-theme .owl-controls{
    margin-top: 40px;
}

.owl-theme .owl-controls .owl-page span{
    width: 70px !important;
    height: 70px !important;
    border-radius: 50% !important;
    border: 4px solid #ccc;
    background: center url("../../images/testimonial/ts1.png") no-repeat !important;
   
    transition: all 0.3s ease-in-out 0s;
}

.owl-theme .owl-controls .owl-page:nth-child(2) span{
    background: center url("../../images/testimonial/ts2.png") no-repeat !important;
   
    transition: all 0.3s ease-in-out 0s;
  
}

.owl-theme .owl-controls .owl-page:nth-child(3) span{
    background: center url("../../images/testimonial/ts3.png") no-repeat !important;
   
}
.owl-theme .owl-controls .owl-page:nth-child(4) span{
    background: center url("../../images/testimonial/ts4.png") no-repeat !important;
   
}
.owl-theme .owl-controls .owl-page:nth-child(5) span{
    background: center url("../../images/testimonial/ts5.png") no-repeat !important;
   
}

.owl-theme .owl-controls.clickable .owl-page:hover span{
    opacity: 0.5;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls .owl-page.active:hover span{
    border-color: #ccc;
    opacity: 1;
    height: 100px !important;
    width: 100px !important;
    border: 3px solid #667EEA;
}

@media (max-width : 768px){
    .testimonial .description{
        font-size: 13px;
        color: #7E8696;
        line-height: 17px;
        text-align: justify;
        position: relative;
        background: #fff;
        padding: 50px 10px 20px 10px;
        font-weight: 400px;
        box-shadow: none !important;
        margin-bottom: 10px;
        font-family: 'Poppins', sans-serif !important;
    }
    .testimonial{
        box-shadow: none;
        
    }

    .testimonial .description:before{
        content: "\f10d";
        font-family: "Font Awesome 5 Free";font-weight: 900;
        position: absolute;
        top: 30px;
        left: 10px;
        font-size: 20px;
        color: #000;
       
    }

    #testimonial-slider{
        padding: 0px 20px 0px 20px;
        background: none;
        text-align: center;
        
    }

    .owl-buttons {
        width: 100%;
        height: 40px;
        position: absolute;
        top: 22%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (max-width: 479px){
    .owl-buttons{ bottom: 30%; }
}



